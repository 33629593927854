program MainPageProject;

uses
  WEBLib.Forms,
  
MainPageUnit in 'MainPageUnit.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateForm(TForm4, Form4);
  Application.Run;
end.
