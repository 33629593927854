unit MainPageUnit;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.StdCtrls, 
WEBLib.ExtCtrls, WEBLib.WebCtrls;

type
  TForm4 = class(TForm)
    PascalABC: TPanel;
    PascalABCTop: TPanel;
    WebPanel1: TPanel;
    WebHTMLAnchor1: THTMLAnchor;
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form4: TForm4;

implementation

{$R *.dfm}

procedure TForm4.LoadDFMValues;
begin
  inherited LoadDFMValues;

  PascalABC := TPanel.Create(Self);
  WebHTMLAnchor1 := THTMLAnchor.Create(Self);
  PascalABCTop := TPanel.Create(Self);
  WebPanel1 := TPanel.Create(Self);

  PascalABC.BeforeLoadDFMValues;
  WebHTMLAnchor1.BeforeLoadDFMValues;
  PascalABCTop.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  try
    Name := 'Form4';
    Width := 640;
    Height := 480;
    PascalABC.SetParentComponent(Self);
    PascalABC.Name := 'PascalABC';
    PascalABC.Left := 0;
    PascalABC.Top := 60;
    PascalABC.Width := 193;
    PascalABC.Height := 420;
    PascalABC.HeightPercent := 100.000000000000000000;
    PascalABC.WidthPercent := 100.000000000000000000;
    PascalABC.Align := alLeft;
    PascalABC.BorderColor := clSilver;
    PascalABC.BorderStyle := bsSingle;
    PascalABC.Color := clMoneyGreen;
    WebHTMLAnchor1.SetParentComponent(PascalABC);
    WebHTMLAnchor1.Name := 'WebHTMLAnchor1';
    WebHTMLAnchor1.Left := 12;
    WebHTMLAnchor1.Top := 24;
    WebHTMLAnchor1.Width := 175;
    WebHTMLAnchor1.Height := 41;
    WebHTMLAnchor1.HeightStyle := ssAuto;
    WebHTMLAnchor1.HeightPercent := 100.000000000000000000;
    WebHTMLAnchor1.WidthPercent := 100.000000000000000000;
    WebHTMLAnchor1.Caption := 'PascalABC.NET wiki';
    WebHTMLAnchor1.HREF := 'https://ecsbooks.co.uk/dokuwiki';
    PascalABCTop.SetParentComponent(Self);
    PascalABCTop.Name := 'PascalABCTop';
    PascalABCTop.Left := 0;
    PascalABCTop.Top := 0;
    PascalABCTop.Width := 640;
    PascalABCTop.Height := 60;
    PascalABCTop.HeightPercent := 100.000000000000000000;
    PascalABCTop.WidthPercent := 100.000000000000000000;
    PascalABCTop.Align := alTop;
    PascalABCTop.BorderColor := clSilver;
    PascalABCTop.BorderStyle := bsSingle;
    PascalABCTop.Caption := 'PascalABC.NET';
    PascalABCTop.ChildOrder := 1;
    PascalABCTop.Color := clMoneyGreen;
    PascalABCTop.Font.Charset := DEFAULT_CHARSET;
    PascalABCTop.Font.Color := clWindowText;
    PascalABCTop.Font.Height := -40;
    PascalABCTop.Font.Name := 'Tahoma';
    PascalABCTop.Font.Style := [];
    PascalABCTop.ParentFont := False;
    WebPanel1.SetParentComponent(Self);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 193;
    WebPanel1.Top := 60;
    WebPanel1.Width := 447;
    WebPanel1.Height := 420;
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alClient;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.Caption := 'Welcome to the website for resources for PascalABC.NET';
    WebPanel1.ChildOrder := 2;
    WebPanel1.Font.Charset := DEFAULT_CHARSET;
    WebPanel1.Font.Color := clWindowText;
    WebPanel1.Font.Height := -33;
    WebPanel1.Font.Name := 'Tahoma';
    WebPanel1.Font.Style := [];
    WebPanel1.ParentFont := False;
  finally
    PascalABC.AfterLoadDFMValues;
    WebHTMLAnchor1.AfterLoadDFMValues;
    PascalABCTop.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
  end;
end;

end.
